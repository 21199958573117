import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'


export const setSubOrgName = (orgId, authKey, userId, subOrgId, name) => async (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgsName
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'suborgid': subOrgId,
  }
  const resp = await Helpers.fetchPutJson(url, headers, {'name': name})
      .then((res) => {
        return res
      })
      .catch((res) => {
        console.log('error res', res)
        return {status: 'FAILURE'}
      })
  const messages = []
  const responses = [resp]
  const actions = ['Sub Organization Name Update']
  for (let rIndex = 0; rIndex < responses.length; rIndex++) {
    messages.push(`${actions[rIndex]} ${responses[rIndex]?.message?.includes('success') ? 'Successful': `Failed: ${responses[rIndex]?.error ? Helpers.parseErrorMessages(responses[rIndex]?.error) : responses[rIndex]?.message ? Helpers.parseErrorMessages(responses[rIndex]?.message) : ''}`}`)
  }
  const wasSuccessful = resp?.message?.includes('success')
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      userMessage: messages.join('\n'),
      notificationIcon: wasSuccessful ? 'check': 'warning',
      notificationType: 2,
    },
  })
  return [wasSuccessful, messages.join('\n')]
}
