/* eslint-disable max-len */
import {ref, get} from 'firebase/database'

export const getModelAccess = (database, orgId) => (dispatch, getState) => {
  const defaultModels = ['genericFormApi2', 'invoices']
  const dbRef = ref(database, `organizations/${orgId}/models`)
  const orgDetails = getState().userReducer?.orgDetails
  return get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const models = snapshot.val()
          const fbModelIds = Object.keys(models).map((modelKey) => {
            if (!models[modelKey]?.isActive || !orgDetails?.['permissions']?.['canUseRikai']) {
              // if model is not active or rikai access is not active
              return null
            }
            return models[modelKey]['modelId'] ? models[modelKey]['modelId'] : modelKey
          })
          const modelIds = fbModelIds.concat(defaultModels)
          return modelIds.filter((modelId, pos) => {
            // modelId is the first occurrence and not null (is active)
            return (modelIds.indexOf(modelId) === pos) && modelId
          })
        } else {
          return defaultModels
        }
      })
      .catch((error) => {
        return defaultModels
      })
}
