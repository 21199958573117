import types from '../types'
import firebase from 'firebase/compat/app'

export const signOut = (orgId) => (dispatch, getState) => {
  const database = getState().firebaseReducer.database
  const logsInterval = getState().userReducer.logsInterval

  if (logsInterval) {
    clearInterval(logsInterval)
  }
  if (orgId) {
    const billingRef = database
        .ref(`organizations/${orgId}/billing`)
    const authTokenRef = database
        .ref(`organizations/${orgId}/activeApis/genericFormApi/authToken`)
    const orgDetailsRef = database
        .ref(`organizations/${orgId}/orgDetails`)
    const userRosterRef = database
        .ref(`organizations/${orgId}/userRoster`)
    const orgActionLogRef = database
        .ref(`organizations/${orgId}/orgActionLogs`)
    billingRef.off()
    authTokenRef.off()
    orgDetailsRef.off()
    userRosterRef.off()
    orgActionLogRef.off()
  }

  firebase.auth().signOut()
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            isSignedIn: false,
            isLoading: false,
            users: null,
            logs: null,
            logsInterval: null,
          },
        })
      })
      .then(() => {
        dispatch({type: 'CLEAR_STORE'})
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            isSignedIn: false,
            isLoading: false,
          },
        })
        if (!orgId) {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: 'You do not appear to have a Lazarus Account. Reach out to your Lazarus Representative or reach out to us through lazarusai.com.',
              notificationType: 3,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        }
      })
      .catch((error) => {
        // console.log('Error on sign out', error)
      })
}

