import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'

import {Card, Icon, Animation, ToolTip} from '@lazarusai/forms-ui-components'
import {storePayload} from '../actions/storePayload'

import '../styles/ModelLimitUsageCard.css'
import Helpers from '../Helpers'

function IndividualLimitCardComponent({
  limitId='',
  limitPercent=null,
  limitKey='',
  limitValue='',
  limitObj={},
  isPercentShowing=false,
  usageOrgId=null,
  usageAuthKey=null,
  ...props
}) {
  const [isLimitExpanded, setIsLimitExpanded] = useState(false)
  const [isCalculationLoading, setIsCalculationLoading] = useState(false)
  // const [calculatedUsage, setCalculatedUsage] = useState(null)
  const [lastCalculationTime, setLastCalculationTime] = useState(null)
  const [activeLimitPercent, setActiveLimitPercent] = useState(null)

  function fetchUpdatedUsage() {
    setIsCalculationLoading(true)
    const urlBase = process.env.REACT_APP_METRICS_URL + 'api/query/models/time-period'
    const isIndirectModel = ['genericFormApi2'].includes(limitObj?.model)
    // only uses filter_indirect_calls when needed to avoid unnecessary compute and be more efficient
    const urlParams = `?filter_demo_init=true&filter_indirect_calls=${isIndirectModel ? 'true' : 'false'}&start_time=${Date.parse(limitObj?.startDate)}&end_time=${limitObj?.endDate ? Date.parse(limitObj?.endDate) : Date.now()}&models=${limitObj?.model}`
    const fullUrl = encodeURI(`${urlBase}${urlParams}`)
    Helpers.fetchGet(fullUrl, {
      orgId: usageOrgId || props.orgId,
      authKey: usageAuthKey || props.authKey,
    }).then((resp) => {
      const newCalculatedUsage = resp?.models?.[0] || {
        'pages': 0,
        'questions': 0,
      }
      newCalculatedUsage['refresh_timestamp'] = Helpers.formatUTCStringAsCurrentDate(new Date().toISOString(), true, true)
      const newCalculatedLimitUsage = structuredClone(props.calculatedLimitUsage) || {}
      newCalculatedLimitUsage[limitId] = newCalculatedUsage
      props.storePayload({
        calculatedLimitUsage: newCalculatedLimitUsage,
      })
      setIsCalculationLoading(false)
    }).catch((error) => {
      const newCalculatedLimitUsage = structuredClone(props.calculatedLimitUsage || {})
      newCalculatedLimitUsage[limitId] = {'error': 'Failed to Calculate Limit Usage'}
      props.storePayload({
        calculatedLimitUsage: newCalculatedLimitUsage,
      })
      setIsCalculationLoading(false)
    })
  }

  function isRenewableLimit(period) {
    return ['daily', 'monthly', 'yearly'].includes(period)
  }

  useEffect(() => {
    if (props.calculatedLimitUsage?.[limitId]) {
      setActiveLimitPercent(
          Math.round((props.calculatedLimitUsage?.[limitId]?.[`${limitObj?.usageType}s`] / limitObj?.limit) * 100 * 10) / 10,
      )
      setLastCalculationTime(props.calculatedLimitUsage?.[limitId]?.refresh_timestamp)
    }
  }, [props.calculatedLimitUsage])

  return (
    <div className={'individual-limit-wrapper'}>
      <div
        key={`limit-row-${limitId}`}
        className={`limit-row ${isLimitExpanded ? 'limit-row-selected': ''}`}
        onClick={() => {
          setIsLimitExpanded(!isLimitExpanded)
        }}
      >
        <div className={`limit-row-title`}>
          {`${Helpers.capitalizeFirstLetter(limitObj?.period)} ${Helpers.capitalizeFirstLetter(limitObj?.usageType)} Limit`}
        </div>
        <div
          className={'limit-header-row'}
        >
          <div
            className={'limit-key'}
            style={{
              color: (activeLimitPercent || limitPercent) >= 90 ? limitObj?.isHard ? '#cf2929': '#ffaa00' : '#fafafa',
              fontWeight: (activeLimitPercent || limitPercent) >= 90 ? 600: 500,
            }}
          >
            {props.calculatedLimitUsage?.[limitId] ?
            (`${props.calculatedLimitUsage?.[limitId]?.[`${limitObj?.usageType}s`]}/${limitObj?.limit} ${limitObj?.usageType}s`) :
            (isPercentShowing? limitKey: `${limitObj?.limit} ${limitObj?.usageType}s per day`)}
          </div>
          <div
            className={'limit-refresh'}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              fetchUpdatedUsage()
            }}
          >
            {isCalculationLoading ?
              <div className='limit-info-loader'>
                {(isCalculationLoading) &&
                <Animation
                  animationState={'generating'}
                  stopAnimation={false}
                  height={'16px'}
                  width={'16px'}
                />
                }
              </div>:
              <Icon icon='sync-outline'/>
            }
            <span>Refresh</span>
          </div>
          {lastCalculationTime && <div
            className={'limit-refresh-date'}
          >
            {`(Refreshed on ${lastCalculationTime})`}
          </div>}
          <div
            className={'limit-value'}
          >
            {`${activeLimitPercent || limitObj?.percentage || 0}%`}
          </div>
        </div>
        {((activeLimitPercent !== null) || isPercentShowing) ? <div
          className={'limit-progress-bar'}
        >
          <div className={'limit-progress-bar-base'}></div>
          <div
            className={'limit-progress-bar-progress'}
            style={{
              width: `${(activeLimitPercent || limitPercent) > 100 ? 100 : `${activeLimitPercent || limitObj?.percentage || 0}`}%`,
              backgroundColor: (activeLimitPercent || limitPercent) >= 90 ? limitObj?.isHard ? '#cf2929': '#ffaa00' : '#fafafa',
            }}
          ></div>
        </div> :
        <div
          className={'progress-not-updated'}
        >
          Progress not calculated.
        </div>
        }
      </div>
      <div
        className={`limit-info ${isLimitExpanded ? 'limit-info-open': 'limit-info-closed'}`}
      >
        <div className='limit-info-side'>
          <div
            className='limit-info-kvp'
          >
            <div className='limit-info-key'>
              {`${Helpers.capitalizeFirstLetter(limitObj?.period)} limit `}
              <span className='enforcement-state'>
                {`(${limitObj?.isHard ? 'Enforced': 'Unenforced'}`}
                <Icon
                  icon='question-mark-circle-outline'
                />
                {`)`}
                <div className='enforcement-explanation'>
                  <ToolTip
                    text={`Usage of the model will ${limitObj?.isHard ? 'be blocked': 'not be blocked'} once the limit is reached.`}
                    onDismiss={() => {}}
                    arrowPosition={0}
                    isVisible={true}
                  />
                </div>
              </span>
            </div>
            <div className='limit-info-value'>
              {limitObj?.period === 'future' ?
              `started ${Helpers.formatUTCStringAsCurrentDate(limitObj?.startDate)}`:
              `${isRenewableLimit(limitObj?.period) ? 'renews': 'ends'} ${Helpers.formatUTCStringAsCurrentDate(limitObj?.endDate)}`}
              {` (${Helpers.getUTCOffsetString()})`}
            </div>
          </div>
          <div
            className='limit-info-kvp'
          >
            <div className='limit-info-key'>
              {limitValue}
            </div>
            <div className='limit-info-value'>
              {limitObj?.period === 'future' ?
              `limit has no end date`:
              `left in limit period`}
            </div>
          </div>
          <div
            className='limit-info-kvp'
          >
            <div className='limit-info-key'>
              {`${limitObj?.limit} ${limitObj?.usageType}s`}
            </div>
            <div className='limit-info-value'>
              total limit
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ModelLimitUsageCard({
  modelName = '',
  limits = null,
  usageOrgId=null,
  usageAuthKey=null,
  ...props
}) {
  return (
    <Card
      title={modelName}
      theme={props.theme}
      className={'model-limit-usage-card'}
    >
      <div className={'model-limit-usage-card-wrapper'}>
        {limits &&
          Object.keys(limits).sort((aId, bId) => {
            // pages over questions
            const aVal1 = limits?.[aId]?.usageType === 'page'? 1: 0
            const bVal1 = limits?.[bId]?.usageType === 'page'? 1: 0
            if (aVal1 !== bVal1) {
              return bVal1 - aVal1
            }
            // period order
            const periodOrder = ['daily', 'monthly', 'yearly', 'range', 'future']
            const aVal2 = periodOrder.indexOf(limits?.[aId]?.period)
            const bVal2 = periodOrder.indexOf(limits?.[bId]?.period)
            return aVal2 - bVal2
          }).map((limitId) => {
            const limitObj = limits?.[limitId]
            const limitPercent = limitObj?.percentage || null
            const [deltaDays, deltaHours, deltaMinutes] = Helpers.calculateTimeDifference(limitObj?.endDate || 0)
            const limitValue = limitObj?.endDate ?
                `${deltaDays > 0 ? deltaDays + ' days ': ''}${deltaHours} hours ${deltaMinutes} mins`:
                'Future Limit'
            const limitUsageValue = limitObj?.usage
            const limitKey = `${limitUsageValue||0}/${limitObj?.limit} ${limitObj?.usageType}s`
            const isPercentShowing = limitObj?.period !== 'daily'
            return (<IndividualLimitCard
              key={`individual-card-${limitId}`}
              limitId={limitId}
              limitPercent={limitPercent}
              limitKey={limitKey}
              limitValue={limitValue}
              limitObj={limitObj}
              isPercentShowing={isPercentShowing}
              theme={props.theme}
              usageOrgId={usageOrgId}
              usageAuthKey={usageAuthKey}
            />)
          })
        }
      </div>
    </Card>
  )
}

const mapStateToProps = (state, ownProps) => ({
  theme: state.userReducer.theme,
  orgId: state.userReducer.orgId,
  authKey: state.userReducer.authKey,
  calculatedLimitUsage: state.userReducer.calculatedLimitUsage,
})
const IndividualLimitCard = connect(mapStateToProps, {storePayload})(IndividualLimitCardComponent)
export default connect(mapStateToProps, {storePayload})(ModelLimitUsageCard)
