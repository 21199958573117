import React, {Fragment, useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {Input, Card, Select, Button, Switch} from '@lazarusai/forms-ui-components'

// import Helpers from '../Helpers'
import {storePayload} from '../actions/storePayload'
import {updateSubOrganizationLimit} from '../actions/updateSubOrganizationLimit'
import {createSubOrganizationLimit} from '../actions/createSubOrganizationLimit'
import {deleteSubOrganizationLimit} from '../actions/deleteSubOrganizationLimit'
import '../styles/LimitInputs.css'
import {getSubOrganizationLimits} from '../actions/getSubOrganizationLimits'
import Helpers from '../Helpers'

function LimitInputs({
  canChangeLimits=true,
  subOrgId=null,
  specificModelAccess=null,
  ...props
}) {
  const [currentLimits, setCurrentLimits] = useState({})
  const [newLimit, setNewLimit] = useState({})

  useEffect(() => {
    if (props.subOrganizationLimits) {
      setCurrentLimits(structuredClone(props.subOrganizationLimits))
    }
  }, [props.subOrganizationLimits])

  function isLimitValid(limitObj) {
    let isValid = true
    const validKeys = ['period', 'usageType', 'limit']
    if (limitObj?.period === 'range') {
      validKeys.push('startDate', 'endDate')
    }
    if (!limitObj?.model && !(limitObj?.models?.length > 0)) { // new limit
      isValid = false
    }
    for (let kIndex = 0; kIndex < validKeys.length; kIndex++) {
      if (!limitObj?.[validKeys[kIndex]]) {
        isValid = false
      }
    }
    return isValid
  }

  function haveLimitsChanged(changed, initial) {
    const changedKeys = Object.keys(changed || {})
    let areThereChanges = false
    for (let cIndex = 0; cIndex < changedKeys.length; cIndex++) {
      if (Array.isArray(changed?.[changedKeys[cIndex]]) && changed?.[changedKeys[cIndex]]?.length > 0 && (changed?.[changedKeys[cIndex]]?.sort() !== initial?.[changedKeys[cIndex]]?.sort())) {
        areThereChanges = true
      } else {
        if (changed?.[changedKeys[cIndex]] !== initial?.[changedKeys[cIndex]]) {
          areThereChanges = true
        }
      }
    }
    return !areThereChanges
  }

  function isFormsModel(modelId) {
    const isTypeForm = props.modelInformation?.[modelId]?.public?.metadata?.demoModelType === 'form'
    return isTypeForm
  }

  const limitInputs = (usageId) => {
    const isNewLimitInput = usageId === null
    return (
      <Card
        theme={props.theme}
        title={isNewLimitInput ? 'New Limit': `Existing Limit (Usage Id: ${usageId})`}
        className={`limit-inputs ${isNewLimitInput ? '': 'limit-inputs-existing'}`}
      >
        <Select
          value={isNewLimitInput ? newLimit?.model : currentLimits[usageId]?.model}
          label={'Model'}
          theme={props.theme}
          isSelectedYellow={true}
          options={(specificModelAccess || props.modelAccess) ? [
            // 'total - All model usage',
            ...(specificModelAccess || props.modelAccess).
                map((initialModelId) => {
                  const hasPIIAccess = (specificModelAccess || props.modelAccess).includes('PII')
                  const isRedact = initialModelId.includes('Redact')
                  const modelId = (!hasPIIAccess && isRedact) ? 'PII': initialModelId
                  const hasModelInformation = props.modelInformation?.[modelId]?.public
                  const isUpdatedRedact = modelId.includes('Redact')
                  if (isUpdatedRedact || !hasModelInformation) {
                    return null
                  } else {
                    return `${modelId} - ${props.modelInformation?.[modelId]?.public?.metadata?.name}${
                      (modelId === 'PII') ? ` & ${props.modelInformation?.['PIIRedact']?.public?.metadata?.name}` : ''
                    }`
                  }
                }).filter((modelString) => modelString !== null),
          ] :
                []}
          onClickOption={(option) => {
            if (canChangeLimits && isNewLimitInput) {
              const newNewLimit = structuredClone(newLimit)
              newNewLimit.model = option.split(' - ')[0]
              if (isFormsModel(newNewLimit.model)) {
                newNewLimit.usageType = 'page'
              }
              setNewLimit(newNewLimit)
            }
          }}
        />
        <Select
          label={`Usage Type${(isFormsModel(isNewLimitInput ? newLimit?.model : currentLimits[usageId]?.model)) ? ' (Only page usage for Forms models)': ''}`}
          theme={props.theme}
          isSelectedYellow={true}
          options={isFormsModel(isNewLimitInput ? newLimit?.model : currentLimits[usageId]?.model) ? ['page']: ['question', 'page']}
          value={(isNewLimitInput ? newLimit?.usageType : currentLimits[usageId]?.usageType)}
          onClickOption={(option) => {
            if (canChangeLimits) {
              if (isNewLimitInput) {
                const newNewLimit = structuredClone(newLimit)
                newNewLimit.usageType = option
                setNewLimit(newNewLimit)
              } else {
                const newCurrentLimits = structuredClone(currentLimits)
                newCurrentLimits[usageId].usageType = option
                setCurrentLimits(newCurrentLimits)
              }
            }
          }}
        />
        <Input
          theme={props.theme}
          type={1}
          inputType={'number'}
          label={'Usage Limit'}
          value={`${(isNewLimitInput ? newLimit?.limit : currentLimits[usageId]?.limit)}`}
          onChange={(e) => {
            if (canChangeLimits) {
              const maxDBInt = 2147483647
              if (isNewLimitInput) {
                const newNewLimit = structuredClone(newLimit)
                newNewLimit.limit = parseInt(e.target.value)
                newNewLimit.limit = newNewLimit.limit > maxDBInt ?
                  maxDBInt : newNewLimit.limit
                setNewLimit(newNewLimit)
              } else {
                const newCurrentLimits = structuredClone(currentLimits)
                newCurrentLimits[usageId].limit = parseInt(e.target.value)
                newCurrentLimits[usageId].limit = newCurrentLimits[usageId].limit > maxDBInt ?
                  maxDBInt : newCurrentLimits[usageId].limit
                setCurrentLimits(newCurrentLimits)
              }
            }
          }}
          placeholder={'Enter the usage limit value...'}
        />
        <Select
          label='Limit Period'
          theme={props.theme}
          isSelectedYellow={true}
          options={['daily', 'monthly', 'yearly', 'range', 'future']}
          value={(isNewLimitInput ? newLimit?.period : currentLimits[usageId]?.period)}
          onClickOption={(option) => {
            if (canChangeLimits) {
              if (isNewLimitInput) {
                const newNewLimit = structuredClone(newLimit)
                newNewLimit.period = option
                setNewLimit(newNewLimit)
              } else {
                const newCurrentLimits = structuredClone(currentLimits)
                newCurrentLimits[usageId].period = option
                setCurrentLimits(newCurrentLimits)
              }
            }
          }}
        />
        {(isNewLimitInput ? newLimit?.period === 'range': true) &&
          <>
            {/* range date input */}
            <Input
              theme={props.theme}
              type={1}
              inputType={'datetime-local'}
              label={`Start date [in your timezone (${Helpers.getUTCOffsetString()})]`}
              value={(isNewLimitInput ? newLimit?.startDate : Helpers.timestampUTCToCurrent(currentLimits[usageId]?.startDate)) || ''}
              onChange={(e) => {
                if (canChangeLimits) {
                  if (isNewLimitInput) {
                    const newNewLimit = structuredClone(newLimit)
                    newNewLimit.startDate = e.target.value
                    setNewLimit(newNewLimit)
                  } else {
                    const newCurrentLimits = structuredClone(currentLimits)
                    newCurrentLimits[usageId].startDate = e.target.value
                    setCurrentLimits(newCurrentLimits)
                  }
                }
              }}
            />
            {(isNewLimitInput || currentLimits[usageId]?.period !== 'future') && <Input
              theme={props.theme}
              type={1}
              inputType={'datetime-local'}
              label={`End date [in your timezone (${Helpers.getUTCOffsetString()})]`}
              value={(isNewLimitInput ? newLimit?.endDate : Helpers.timestampUTCToCurrent(currentLimits[usageId]?.endDate)) || ''}
              onChange={(e) => {
                if (canChangeLimits) {
                  if (isNewLimitInput) {
                    const newNewLimit = structuredClone(newLimit)
                    newNewLimit.endDate = e.target.value
                    setNewLimit(newNewLimit)
                  } else {
                    const newCurrentLimits = structuredClone(currentLimits)
                    newCurrentLimits[usageId].endDate = e.target.value
                    setCurrentLimits(newCurrentLimits)
                  }
                }
              }}
            />}
          </>
        }
        <div
          className='disabled disabled-hard-limits'
        >
          <p className='input-header'>
            {'Hard Limit (Coming Soon - Only Soft Limits for now)'}
          </p>
          <Switch
            disabled={true} // Only allow soft limits for the time being
            theme={props.theme}
            label={`Should the organization's requests be denied after this limit is reached?`}
            value={(isNewLimitInput ? newLimit?.isHard : currentLimits[usageId]?.isHard) || false}
            onChange={(e) => {
              if (canChangeLimits) {
                if (isNewLimitInput) {
                  const newNewLimit = structuredClone(newLimit)
                  newNewLimit.isHard = e.target.checked
                  setNewLimit(newNewLimit)
                } else {
                  const newCurrentLimits = structuredClone(currentLimits)
                  newCurrentLimits[usageId].isHard = e.target.checked
                  setCurrentLimits(newCurrentLimits)
                }
              }
            }}
          />
        </div>
        <div
          className={`limit-action-row ${(isNewLimitInput) ? '': 'limit-split-action-row'}`}
        >
          {isNewLimitInput ?
            <>
              <Button
                text={'Create New Limit'}
                theme={props.theme}
                disabled={haveLimitsChanged(
                    newLimit,
                    {},
                ) || !isLimitValid(newLimit)}
                onClick={onCreateLimit}
              />
            </> :
            <>
              {/* <Button
                text={'Update Limit'}
                theme={props.theme}
                disabled={haveLimitsChanged(
                    currentLimits[usageId],
                    props.subOrganizationLimits[usageId],
                ) || !isLimitValid(currentLimits[usageId])}
                onClick={() => onUpdateLimit(usageId)}
              /> */}
              <Button
                text={'Delete Limit'}
                theme={props.theme}
                type={10}
                onClick={() => onDeleteLimit(usageId)}
              />
              <div className='update-alt-text'>
                You can enforce updated limits by deleting existing versions of limits and recreating them with changes.
              </div>
            </>
          }
        </div>
      </Card>
    )
  }

  async function onDeleteLimit(usageId) {
    const isUserSure = confirm('Are you sure you want to remove this limit?')
    if (isUserSure) {
      const deleteLimit = props.subOrganizationLimits?.[usageId]
      const replicaIds = []
      if (repeatingPeriods.includes(deleteLimit?.period)) {
        // If deleting a repeating limit - delete all upcoming replicas
        const limit = props.subOrganizationLimits?.[usageId]
        const usageIds = Object.keys(props.subOrganizationLimits || {})
        const currentTimeUTC = new Date().toISOString()
        for (let uIndex = 0; uIndex < usageIds.length; uIndex++) {
          const uId = usageIds[uIndex]
          if (uId !== usageId) {
            const compareLimit = props.subOrganizationLimits?.[uId]
            if (
              limit?.period === compareLimit?.period &&
              limit?.limit === compareLimit?.limit &&
              limit?.startDate.split('T')[1] === compareLimit?.startDate.split('T')[1] &&
              compareLimit?.startDate > currentTimeUTC &&
              limit?.endDate.split('T')[1] === compareLimit?.endDate.split('T')[1]
            ) {
              replicaIds.push(uId)
            }
          }
        }
      }
      const promises = []
      promises.push(new Promise(async (resolve) => {
        await props.deleteSubOrganizationLimit(subOrgId, usageId)
        resolve(true)
      }))
      for (let pIndex = 0; pIndex < replicaIds.length; pIndex++) {
        promises.push(new Promise(async (resolve) => {
          await props.deleteSubOrganizationLimit(subOrgId, replicaIds[pIndex], false)
          resolve(true)
        }))
      }
      await Promise.all(promises)
      props.getSubOrganizationLimits(subOrgId, props.database)
    }
  }

  // async function onUpdateLimit(usageId) {
  //   const body = structuredClone(currentLimits[usageId])
  //   await props.updateSubOrganizationLimit(subOrgId, usageId, body)
  //   props.getSubOrganizationLimits(subOrgId)
  // }

  async function onCreateLimit() {
    const body = structuredClone(newLimit)
    body['type'] = 'usage'
    // Adjust Times
    if (body?.['startDate']) {
      body['startDate'] = Helpers.timestampCurrentToUTC(body['startDate'])
    }
    if (body?.['endDate']) {
      body['endDate'] = Helpers.timestampCurrentToUTC(body['endDate'])
    }
    const success = await props.createSubOrganizationLimit(subOrgId, body)
    if (success) {
      setNewLimit({})
    }
    props.getSubOrganizationLimits(subOrgId, props.database)
  }

  const repeatingPeriods = ['daily', 'monthly', 'yearly']

  return (<>
    <div className='limit-header'>Limits</div>
    <div
      className={'limits'}
    >
      {Object.keys(props.subOrganizationLimits || {})
          .filter((usageId) => {
            const limit = props.subOrganizationLimits?.[usageId]
            if (repeatingPeriods.includes(limit?.period)) {
              const currentTimeUTC = new Date().toISOString()
              if (
                limit?.startDate > currentTimeUTC ||
                limit?.endDate < currentTimeUTC
              ) {
                return false
              }
            }
            return true
          })
          .map((usageId) => {
            return (<Fragment
              key={`${usageId}-limit-fragment`}
            >
              {limitInputs(usageId)}
            </Fragment>)
          })}
      {limitInputs(null)}
    </div>
  </>)
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  orgId: state.userReducer.orgId,
  subOrganizationMetrics: state.userReducer.subOrganizationMetrics,
  subOrganizationDetails: state.userReducer.subOrganizationDetails,
  modelAccess: state.userReducer.modelAccess,
  modelInformation: state.userReducer.modelInformation,
  authKey: state.userReducer.authKey,
  vkgDomain: state.userReducer.vkgDomain,
  subOrgAuthKey: state.userReducer.subOrgAuthKey,
  subOrganizationLimits: state.userReducer.subOrganizationLimits,
})

export default connect(mapStateToProps, {
  storePayload, updateSubOrganizationLimit, createSubOrganizationLimit,
  deleteSubOrganizationLimit, getSubOrganizationLimits,
})(LimitInputs)
