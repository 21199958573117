import React from 'react'
import {connect} from 'react-redux'

import {Card, Animation} from '@lazarusai/forms-ui-components'
import ModelLimitUsageCard from './ModelLimitUsageCard'
import '../styles/LimitUsage.css'

function LimitUsage({
  lastUpdate = '12:00AM EST',
  limits = undefined,
  showTitle=true,
  usageOrgId=null,
  usageAuthKey=null,
  ...props
}) {
  return (
    <Card
      title={showTitle ? 'Limit Usage': undefined}
      theme={props.theme}
      className={'limit-usage-card'}
    >
      <div className={'last-update-notification'}>
        {`* Data was last updated at ${lastUpdate}`}
      </div>
      <div className={'limit-usage-cards-wrapper'}>
        {limits && props.modelInformation &&
        Object.keys(limits)
            .sort((aId, bId) => {
              const aPriority = props.modelInformation?.[aId]?.public?.metadata?.priority
              const bPriority = props.modelInformation?.[bId]?.public?.metadata?.priority
              return bPriority - aPriority
            })
            .map((modelId, mIndex) => {
              const modelName = props.modelInformation?.[modelId]?.public?.metadata?.name
              if (!modelName) {
                return (<></>)
              }
              return (
                <ModelLimitUsageCard
                  key={`${modelName}-${mIndex}`}
                  modelName={modelName}
                  limits={limits?.[modelId]}
                  usageOrgId={usageOrgId}
                  usageAuthKey={usageAuthKey}
                />
              )
            })
        }
      </div>
      {(limits === undefined || !props.modelInformation) && <div className='limits-loading'><Animation
        animationState={'generating'}
        stopAnimation={false}
        height={'48px'}
        width={'48px'}
      /></div>}
      {limits !== undefined && !Object.keys(limits).length && <div className='no-active-limits'>No active limits</div>}
    </Card>
  )
}

const mapStateToProps = (state, ownProps) => ({
  theme: state.userReducer.theme,
  modelInformation: state.userReducer.modelInformation,
})

export default connect(mapStateToProps)(LimitUsage)
