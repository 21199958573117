import types from '../types'

const initialState = {
  /* Default values here */
  isLoading: true,
  hasAttemptedSignIn: false,
  isNew: true,
  logData: {},
  isNotificationVisible: true,
  isFirstTimeUser: false,
  theme: 'dark',
  isSaveCred: true,
  isModelMenuShowing: false,
  timeOfLastAction: Date.now(),
  mfaModalPage: 1,
  subOrganizationMetrics: null,
  selectedModel: null,
  isVerboseMode: true,
  lastRequest: null,
  canceledRequests: [],
  fillablePDFCheckCounter: 0,
  orgModularMetricsFeed: {},
  vkgDomain: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case types.STORE_PAYLOAD:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
