/* methods used in multiple components */
import endpoints from './endpoints.json'

const Helpers = {
  time() {
    const now = new Date()
    const hour = now.getHours()
    const min = now.getMinutes()
    return `${hour}:${min}`
  },

  async fetchPostJson(url, data, headers = {}) {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const headerKeys = Object.keys(headers)
    if (headerKeys?.length > 0) {
      headerKeys.forEach((key) => {
        myHeaders.append(key, headers[key])
      })
    }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return response.json()
  },

  async fetchPostJsonWithStatus(url, data, headers = {}) {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const headerKeys = Object.keys(headers)
    if (headerKeys?.length > 0) {
      headerKeys.forEach((key) => {
        myHeaders.append(key, headers[key])
      })
    }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return [response.json(), response.status]
  },

  async fetchPutJson(url, headers, data) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    return response.json();
    // parses JSON response into native JavaScript objects
  },

  async fetchGet(url, headers) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return response.json()
  },

  async fetchGetText(url, headers) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return response.text()
  },

  async fetchDeleteJson(url, headers, data) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    return response.json();
    // parses JSON response into native JavaScript objects
  },

  notifyUser(email, topic = 'password_update') {
    const url = process.env.REACT_APP_URL + endpoints.sendEmail
    const data = {
      to_email: email,
      topic,
    }
    this.fetchPostJson(url, data)
        .then((res) => {
          console.log('User has been notified via email', res)
        })
        .catch((err) => {
          console.log('There was an error notifying this user', err)
        })
  },

  copyToClipBoard(copyText, successCb = () => {}) {
    navigator.permissions
        .query({name: 'clipboard-write'})
        .then((result) => {
          if (result.state === 'granted' || result.state === 'prompt') {
          /* write to the clipboard */
            navigator.clipboard
                .writeText(copyText)
                .then(() => {
                  /* clipboard successfully set */
                  successCb()
                })
                .catch(clipboardCopyFallback)
          } else {
            clipboardCopyFallback()
          }
        })
        .catch(clipboardCopyFallback)

    function clipboardCopyFallback() {
      /*
        Necessary for Safari/Firefox or situtations without clipboard access:
        -Create invisible DOM element that contains copyText
        -Focus/select DOM element
        -copy content of DOM element
        -remove DOM element
      */
      const textArea = document.createElement('textarea')
      textArea.value = copyText

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      // create DOM element, focus, select DOM element
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      // copy content of DOM element
      try {
        /*
          MDN says document.execCommand is deprecated
          but at the time of writing this (Feb 2023),
          it has wide browser support
        */
        document.execCommand('copy')
        successCb()
      } catch (err) {
        console.error('Unable to copy to clipboard: ', err)
      }
      // remove DOM element
      document.body.removeChild(textArea)
    }
  },

  timeStampToDate(timestamp) {
    if (!timestamp) {
      return 'N/A'
    }
    const dateObj = new Date(timestamp)
    return (
      dateObj.getMonth() +
      1 +
      '/' +
      dateObj.getDate() +
      '/' +
      dateObj.getFullYear()
    )
  },

  timeStampToTime(timestamp) {
    const dateObj = new Date(timestamp)
    const hours = dateObj.getHours().toString()
    const minutes = dateObj.getMinutes().toString()
    const formattedHours = hours.length === 2 ? hours : '0' + hours
    const formattedMinutes = minutes.length === 2 ? minutes : '0' + minutes
    return `${formattedHours}:${formattedMinutes}`
  },

  formatDate(str) {
    if (str) {
      const dashB = str.slice(5, str.length) + '/' + str.slice(0, 4)
      return dashB.replace('-', '/')
    } else {
      return ''
    }
  },

  /* Used to trigger callback after typing has stopped for pause length */
  toggleTyping(timeOfType, callback, pauseLength = 2000) {
    setTimeout(() => {
      const now = Date.now()
      if (now - timeOfType >= pauseLength) {
        callback()
      }
    }, pauseLength)
    return false
  },

  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    return ''
  },

  lowerCaseFirstLetter(string) {
    if (string) {
      return string.charAt(0).toLowerCase() + string.slice(1)
    }
    return ''
  },

  truncateString(str, limit) {
    if (str?.length > limit) {
      return str.slice(0, limit) + '...'
    } else {
      return str
    }
  },

  getAsterisks(val) {
    return [...new Array(val)].map(() => '*').join('')
  },

  openLinkInNewTab(link) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: link,
    }).click();
  },

  downloadFile(fileText, fileType, fileName) {
    const blob = new Blob([fileText], {type: fileType});
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', fileName);
    a.click()
  },

  removeBadKVPs(obj) {
    const keys = Object.keys(obj || {})
    for (let kIndex = 0; kIndex < keys.length; kIndex++) {
      if (obj[keys[kIndex]] === '' || !obj[keys[kIndex]]) {
        delete obj[keys[kIndex]]
      } else if (typeof obj[keys[kIndex]] === 'object' && !Array.isArray(obj[keys[kIndex]])) {
        obj[keys[kIndex]] = this.removeBadKVPs(obj[keys[kIndex]])
      }
    }
    return obj
  },

  parseErrorMessages(errors, initialPath='') {
    if (typeof errors === 'string') {
      return [errors]
    }
    const errorKeys = Object.keys(errors)
    let messages = []
    for (let i = 0; i < errorKeys.length; i++) {
      if (Array.isArray(errors[errorKeys[i]])) {
        messages.push(`${initialPath ? (initialPath + ' '): ''}${errorKeys[i]} - ${errors[errorKeys[i]][0]}`)
      } else if (typeof errors[errorKeys[i]] === 'string') {
        messages.push(`${initialPath ? (initialPath + ' '): ''}${errorKeys[i]} - ${errors[errorKeys[i]]}`)
      } else {
        messages = [...messages, this.parseErrorMessages(errors[errorKeys[i]], errorKeys[i])]
      }
    }
    return messages
  },

  doesStringHaveBlockedFirebaseKeys(value) {
    const regex = /[.$#[\]]/;
    return regex.test(value)
  },

  replaceBlockedFirebaseKeys(value) {
    const regex = /[.$#[\]]/;
    return value.replace(regex, '')
  },

  encodeVKGId(vkgId) {
    return vkgId.replaceAll('.', '<<period>>')
        .replaceAll('$', '<<dollar>>')
        .replaceAll('#', '<<hash>>')
        .replaceAll('[', '<<openbracket>>')
        .replaceAll(']', '<<closebracket>>')
        .replaceAll('/', '<<slash>>')
  },

  decodeVKGId(encodedVkgId) {
    return encodedVkgId.replaceAll('<<period>>', '.')
        .replaceAll('<<dollar>>', '$')
        .replaceAll('<<hash>>', '#')
        .replaceAll('<<openbracket>>', '[')
        .replaceAll('<<closebracket>>', ']')
        .replaceAll('<<slash>>', '/')
  },

  removeSpecialCharacters(val) {
    return val.replaceAll('.', '')
        .replaceAll('$', '')
        .replaceAll('#', '')
        .replaceAll('[', '')
        .replaceAll(']', '')
        .replaceAll('/', '')
  },

  encodeVKGAccess(vkgAccess) {
    const newVKGAccess = {}
    const vkgAccessKeys = Object.keys(vkgAccess || {})
    for (let vIndex = 0; vIndex < vkgAccessKeys.length; vIndex++) {
      newVKGAccess[this.encodeVKGId(vkgAccessKeys[vIndex])] = vkgAccess[vkgAccessKeys[vIndex]]
    }
    return newVKGAccess
  },

  decodeVKGAccess(vkgAccess) {
    const newVKGAccess = {}
    const vkgAccessKeys = Object.keys(vkgAccess || {})
    for (let vIndex = 0; vIndex < vkgAccessKeys.length; vIndex++) {
      newVKGAccess[this.decodeVKGId(vkgAccessKeys[vIndex])] = vkgAccess[vkgAccessKeys[vIndex]]
    }
    return newVKGAccess
  },

  vkgAccessString(user) {
    if (user?.permissions?.writeVKG) {
      return 'Read/Write - ' + this.getAccessString(user)
    } else if (user?.permissions?.readVKG) {
      return 'Read - ' + this.getAccessString(user)
    } else if (user?.role === 'admin' && !user?.vkgAccess) {
      return 'Legacy Admin Access'
    } else {
      return 'No Access'
    }
  },

  getAccessString(user) {
    if (user?.vkgAccess?.vkgs?.includes('read') || user?.vkgAccess?.vkgs?.includes('write')) {
      return 'Full Access'
    }
    return 'Limited Access'
  },

  secondsToTime(secondsInt) {
    if (!isNaN(secondsInt)) {
      const sec_num = parseInt(secondsInt, 10) // don't forget the second param
      let hours = Math.floor(sec_num / 3600)
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
      let seconds = sec_num - (hours * 3600) - (minutes * 60)

      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      return hours + ':' + minutes + ':' + seconds
    } else {
      return ''
    }
  },

  mysteryCaseToDisplay(str) {
    if (str) {
      if (str.includes('_')) {
        return this.snakeCaseToDisplay(str)
      } else {
        return this.camelCaseToDisplay(str)
      }
    }
  },

  camelCaseToDisplay(str) {
    const result = str.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
  },

  snakeCaseToDisplay(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1)
    while (result.includes('_')) {
      const indexOfFirst_ = result.indexOf('_')
      result = result.slice(0, indexOfFirst_) + ' ' + result.charAt(indexOfFirst_ + 1).toUpperCase() + result.slice(indexOfFirst_ + 2)
    }
    return result
  },

  timestampCurrentToUTC(timestampString) {
    try {
      const timestampDate = new Date(timestampString)
      return timestampDate.toISOString()
    } catch (error) {
      return ''
    }
  },

  timestampUTCToCurrent(timestampString) {
    try {
      const timestampDate = new Date(timestampString)
      const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000
      const adjustedDate = new Date(timestampDate.getTime() - timeZoneOffset)
      return adjustedDate.toISOString().slice(0, 16)
    } catch (error) {
      return ''
    }
  },

  getUTCOffsetString() {
    const fullOffset = (new Date()).getTimezoneOffset()
    const hourOffset = Math.abs(fullOffset / 60)
    const signOffset = hourOffset < 0 ? '+': '-'
    const intHourOffset = parseInt(hourOffset)
    const intMinOffset = parseInt((60 * (hourOffset - intHourOffset)))
    const timeOffset = `${hourOffset < 10 ? '0': ''}${intHourOffset}:${intMinOffset < 10 ? '0': ''}${intMinOffset}`
    return `UTC${signOffset}${timeOffset}`
  },

  calculateTimeDifference(futureTimestamp) {
    const msInDay = (24 * 60 * 60 * 1000)
    const msInHour = (60 * 60 * 1000)
    const msInMinute = (60 * 1000)
    // Assumes Timestamp is UTC
    const dateDiff = new Date(new Date(futureTimestamp) - new Date())
    const dateDiffMS = dateDiff.valueOf()
    const deltaDays = Math.round(dateDiffMS/msInDay - .5)
    let interDiff = (dateDiffMS - deltaDays * msInDay)
    const deltaHours = Math.round(interDiff/msInHour - .5)
    interDiff = interDiff - deltaHours * msInHour
    const deltaMinutes = Math.round(interDiff/msInMinute - .5)
    return [deltaDays, deltaHours, deltaMinutes]
  },

  formatUTCStringAsCurrentDate(timestampString, isTimeAdded=true, justTime=false) {
    const dateObj = new Date(this.timestampUTCToCurrent(timestampString))
    return `${justTime ? '': dateObj.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })}${isTimeAdded ? (justTime ? '' : ' ') + dateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}): ''}`
  },
}

export default Helpers
