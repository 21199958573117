import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {signIn} from '../actions/signIn'
import {signInWithGoogle} from '../actions/signInWithGoogle'
import {signUp} from '../actions/signUp'
import {enterMFACode} from '../actions/enterMFACode'
import {storePayload} from '../actions/storePayload'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
import '../styles/Modal.css'
import '../styles/Auth.css'
import {
  TabContainer,
  Switch,
  Input,
  Button,
  Icon,
  Modal,
} from '@lazarusai/forms-ui-components'
import logo from '../images/FormsLogo.png'
import firebase from 'firebase/compat/app'

const defaultForm = {
  isSaveCred: true,
  // username: '',
  // password: '',
  signInUsername: '',
  signInPassword: '',
  orgName: '',
  firstName: '',
  lastName: '',
  cookiesEnabled: true,
}

function AuthModal(props) {
  const [authForm, setAuthForm] = useState(defaultForm)
  const [activeTab, setActiveTab] = useState('signIn')
  const [showSignInPassword, setShowSignInPassword] = useState(false)
  const [MFAOTPInput, setMFAOTPInput] = useState('')

  useEffect(() => {
    setAuthForm(defaultForm)
  }, [])

  function onChangeForm(e) {
    const newAuthForm = {...authForm}
    newAuthForm[e.target.name] = e.target.value
    setAuthForm(newAuthForm)
  }

  function onChangeCheckBox(e) {
    const newAuthForm = {...authForm}
    const newVal = !authForm[e.target.name]
    newAuthForm[e.target.name] = newVal
    setAuthForm(newAuthForm)
    props.storePayload({isSaveCred: newVal})
  }

  function onSignIn(e) {
    e.preventDefault()
    props.signIn(
        authForm.signInUsername,
        authForm.signInPassword,
        authForm.isSaveCred,
        props.database,
    )
  }

  const forgotPasswordEvent = {
    email: authForm.signInUsername,
    page: window.location.pathname.substring(1),
    time: Date.now(),
    user: window.navigator.userAgent,
    event_type: 'api',
  }

  function onClickForgotPassword(e) {
    e.stopPropagation()
    if (authForm.signInUsername) {
      firebase
          .auth()
          .sendPasswordResetEmail(authForm.signInUsername)
          .then((res) => {
            props.storePayload({
              userMessage: 'Please check your email to change your password.',
              notificationType: 1,
              notificationIcon: 'check',
            })
            props.logAnalyticsEvent('forgot_password', {
              ...forgotPasswordEvent,
              result: 'reset_sent',
            })
          })
          .catch((err) => {
            props.storePayload({
              userMessage: 'Email not found. Please try again.',
              notificationType: 2,
              notificationIcon: 'warning',
            })
            props.logAnalyticsEvent('forgot_password', {
              ...forgotPasswordEvent,
              result: 'email_not_found',
            })
          })
    } else {
      props.storePayload({
        userMessage: 'Please include your email.',
        notificationType: 2,
        notificationIcon: 'warning',
      })
      props.logAnalyticsEvent('forgot_password', {
        ...forgotPasswordEvent,
        result: 'email_not_given',
      })
    }
  }

  function clickResources(event) {
    props.logAnalyticsEvent('resources_clicked', {
      page: window.location.pathname.substring(1),
      time: Date.now(),
      user: window.navigator.userAgent,
      event_type: 'navigation',
    })
  }

  const signInInputs = (
    <div id='sign-in-inputs'>
      <Input
        name='signInUsername'
        label='Username'
        theme={props.theme}
        id='signInUsername'
        key='signInUsername'
        autoComplete='username'
        value={authForm.signInUsername}
        onChange={onChangeForm}
        inputType='email'
      />
      <Input
        name='signInPassword'
        label='Password'
        theme={props.theme}
        autoComplete='password'
        id='signInPassword'
        key='signInPassword'
        inputType={showSignInPassword ? 1 : 'password'}
        value={authForm.signInPassword}
        onChange={onChangeForm}
        iconRight={
          <Icon
            icon={showSignInPassword ? 'eye-off-2-outline' : 'eye-outline'}
            key={showSignInPassword ? 'show-sign-in' : 'no-show-sign-in'}
            onClick={(e) => {
              setShowSignInPassword(!showSignInPassword)
            }}
            className='password-change-icon'
          />
        }
      />
      <div className='checkbox-container'>
        <Switch
          type='checkbox'
          name='isSaveCred'
          id='isSaveCred'
          theme={props.theme}
          label='Save Credentials'
          className='checkbox'
          value={authForm.isSaveCred}
          checked={authForm.isSaveCred}
          onChange={onChangeCheckBox}
        />
      </div>
    </div>
  )

  function onSubmitMFA(e) {
    e.preventDefault()
    props.enterMFACode(MFAOTPInput.slice(0, 3) + MFAOTPInput.slice(4, 7), props.mfaError)
  }

  function clickResources(event) {
    props.logAnalyticsEvent('resources_clicked', {
      page: window.location.pathname.substring(1),
      time: Date.now(),
      user: window.navigator.userAgent,
      event_type: 'navigation',
    })
  }

  function formatOTPInput(input) {
    // numbers only
    if (isNaN(input[input.length - 1])) return input.slice(0, -1)
    // auto add '-'
    if (input.length > 3 && !input.includes('-')) return input.slice(0, 3) + '-' + input.slice(3, input.length)
    // no longer than 6 digits (not counting dash)
    if (input.length > 7) return input.slice(0, 7)
    return input
  }

  function isValidOTP(input) {
    return (input.length === 7 && !isNaN(input.slice(0, 3) && !isNaN(input.slice(4, 7))))
  }

  const MFATabContent = (
    <form className='mfa-form' onSubmit={onSubmitMFA}>
      <p className='text-xl'>
      Enter Two-Factor Authentication Code
      </p>
      <p>
      This account has Two-Factor Authentication enabled. Retrieve the six-digit code from your authenticator app and enter it below.
      </p>
      <br/><br/><br/><br/><br/>
      <Input
        type={1}
        label='Authentication Code'
        onChange={(e) => {
          setMFAOTPInput(formatOTPInput(e.target.value))
        }}
        placeholder = '000-000'
        theme={props.theme}
        value={MFAOTPInput}
        key='MFAInput'
        autocomplete={false}
      />
      <footer>
        <Button
          theme={props.theme}
          disabled={!isValidOTP(MFAOTPInput)}
          buttonType='submit'
          text='Enter Code'
          icon={<Icon icon='arrow-forward-outline' />}
          iconPosition='right'
          iconJustify='edge'
        />
        <Button
          theme={props.theme}
          type='ghost-error'
          onClick={() => props.storePayload({onMFATab: false})}
          text='Cancel'
          icon={<Icon icon='close-outline' />}
          iconPosition='right'
          iconJustify='edge'
        />
      </footer>
    </form>
  )

  const signInTabContent = (
    <form
      className='modal-form sign-in-form show-scrollbar'
      onSubmit={onSignIn}
      name='Sign In'
      key='Sign In'
    >
      {signInInputs}
      <footer className='modal-footer'>
        <Button
          theme={props.theme}
          buttonType='submit'
          text='Sign In'
          icon={<Icon icon='arrow-forward-outline' />}
          iconPosition='right'
          iconJustify='edge'
        />
        <Button
          type={1}
          text='Sign In With Google'
          theme={props.theme}
          onClick={(e) => {
            e.preventDefault()
            props.signInWithGoogle(props.database, authForm.isSaveCred)
          }}
          icon={
            <Icon
              icon={'google-outline'}
            />
          }
          iconJustify={'edge'}
          iconPosition={'right'}
        />
        <Button
          theme={props.theme}
          type='ghost-warn'
          onClick={onClickForgotPassword}
          text='Forgot Password'
          icon={<Icon icon='alert-circle-outline' />}
          iconPosition='right'
          iconJustify='edge'
        />
      </footer>
    </form>
  )

  return (
    <>
      <Modal
        theme={props.theme}
        isVisible={props.isShowing}
        showClose={false}
        showConfirm={false}
        height='90vh'
        width='32rem'
        navPadding={false}
        style={{background: 'transparent'}}
        bodyStyle={{
          padding: 0,
          boxShadow: 'none',
          background: 'transparent',
        }}
        contentStyle={{
          padding: 0,
          gridTemplateRows: 'auto 1fr',
          display: 'grid',
          height: '100%',
          maxHeight: 'calc(100% - 4em)',
        }}
      >
        <header className='modal-header'>
          <img src={logo} alt='' />
          <div className='header-name'>
            <span className='name-small'>Lazarus</span>
            <span>Forms</span>
          </div>
        </header>
        {props.onMFATab ? MFATabContent : <TabContainer
          theme={props.theme}
          style={{backgroundColor: 'var(--colors-neutral-gray20)'}}
          activeTab={activeTab}
          onTabChange={(tabName) => {
            setActiveTab(tabName)
          }}
          tabContent={{
            signIn: {
              render: signInTabContent,
              isDisabled: false,
              title: 'Sign In',
            },
            getAccess: {
              link: 'https://emvnha23ura.typeform.com/lazarus-rikai',
              isDisabled: false,
              title: 'Get Access',
            },
            resources: {
              link: 'https://app.lazarusforms.com/docs#lazarus-forms-api',
              isDisabled: false,
              title: 'Resources',
              onClickEvent: clickResources,
            },
          }}
        >
        </TabContainer>}
      </Modal>
      <footer className='copyright-footer'>
        &copy; Lazarus Enterprises Inc.
        <br/>
        <a href='https://www.lazarusforms.com/legal#terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms and conditions</a>
        &nbsp;&#x2022;&nbsp;
        <a href='https://www.lazarusforms.com/legal#privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy policy</a>
      </footer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  onMFATab: state.userReducer.onMFATab,
  mfaError: state.userReducer.mfaError,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  userMessage: state.userReducer.userMessage,
  notificationType: state.userReducer.notificationType,
  notificationIcon: state.userReducer.notificationIcon,
  theme: state.userReducer.theme,
  isSignedIn: state.userReducer.isSignedIn,
  analytics: state.firebaseReducer.analytics,
})

export default connect(
    mapStateToProps,
    {signIn, signUp, storePayload, logAnalyticsEvent, enterMFACode, signInWithGoogle},
)(AuthModal)
