import types from '../types'
import {getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import firebase from 'firebase/compat/app'

export const signInWithGoogle = (database, isSaveCred) => async (dispatch, getState) => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth()
  auth.useDeviceLanguage()

  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      isLoading: true,
    },
  })

  const persistence = isSaveCred ? 'SESSION' : 'NONE'

  firebase.auth().setPersistence(firebase.auth.Auth.Persistence[persistence])
      .then(() => {
        signInWithPopup(auth, provider)
            .then((result) => {
              console.log(result)
              // This gives you a Google Access Token. You can use it to access Google APIs.
              const credential = GoogleAuthProvider.credentialFromResult(result);
              const token = credential.accessToken;
              const user = result.user;
              console.log(token, user)
            })
            .catch(handleMFA)
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(error, errorCode, errorMessage)
              let messageAddition = null
              switch (errorCode) {
                case 'auth/popup-blocked':
                  messageAddition = 'Google SSO pop up was blocked by your browser. Try again and/or update permissions to allow pop ups.'
                  break;
                case 'auth/admin-restricted-operation':
                  messageAddition = 'Your email is not registered with an account. Reach out to your Lazarus Representative or through lazarusai.com.'
                  break;
              }
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  userMessage: `Google SSO Failure${messageAddition ? ': ' + messageAddition: ''}`,
                  notificationType: 3,
                  notificationIcon: 'warning',
                  isLoading: false,
                },
              })
            });
      })
      .catch((error) => console.log(error))


  function handleMFA(error) {
    if (error.code === 'auth/multi-factor-auth-required') {
      // Notify user to look for a TOTP code
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          // directs AuthModal to switch to the MFA Tab
          onMFATab: true,
          // needed for loggin successful mfa
          // needs to be passed to the enterMFACode action for some reason
          mfaError: error,
          // had to add this or it would not stop loading
          isLoading: false,
        },
      })
      // propagate to next catch block if error isn't MFA-related
    } else throw error
  }
}
