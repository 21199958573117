import types from '../types'
import Helpers from '../Helpers'
import endpoints from '.././endpoints.json'

import {ref, get} from 'firebase/database'

const repeatingPeriods = ['daily', 'monthly', 'yearly', 'range']

function getUsagePercentage(orgId, modelId, limitId, database) {
  const dbRef = ref(database, `thresholds/${orgId}/${modelId}/${limitId}`)
  return get(dbRef)
      .then((snapshot) => {
        const val = snapshot.val()
        const percentage = (Math.round(val?.['usage_percentage'] * 10) / 10) || 0
        const value = val?.['usage_value'] || 0
        return [percentage, value]
      })
      .catch((err) => {
        console.log('error', err)
        return [0, 0]
      })
}

export const getOrganizationLimits = (orgId, database) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const authKey = userReducer.authKey
  const orgId = userReducer.orgId
  const userId = userReducer.user.uid
  const urlParams = `?retrieveOrgId=${orgId}`
  Helpers.fetchGet(
      process.env.REACT_APP_METRICS_URL + endpoints.limits + urlParams,
      {
        'orgId': orgId,
        'authKey': authKey,
        'userId': userId,
      },
  ).then(async (res) => {
    if (res?.limits?.[orgId]?.usageLimits) {
      const usageLimits = res?.limits?.[orgId]?.usageLimits
      const limits = {}
      const modelLimitKeys = Object.keys(usageLimits || {}).sort()
      for (let mIndex = 0; mIndex < modelLimitKeys.length; mIndex++) {
        const modelId = modelLimitKeys[mIndex]
        const limitKeys = Object.keys(usageLimits?.[modelId] || {}).sort()
        const modelLimits = {}
        for (let lIndex = 0; lIndex < limitKeys.length; lIndex++) {
          const limit = usageLimits?.[modelId]?.[limitKeys[lIndex]]
          limit['model'] = modelId
          if (repeatingPeriods.includes(limit?.period)) {
            const currentTimeUTC = new Date().toISOString()
            if (
              !(limit?.startDate > currentTimeUTC ||
              limit?.endDate < currentTimeUTC)
            ) { // If repeating limit is valid
              const [percentage, usage] = await getUsagePercentage(orgId, modelId, limitKeys[lIndex], database)
              limit['percentage'] = percentage
              limit['usage'] = usage
              modelLimits[limitKeys[lIndex]] = limit
            }
          } else { // If not a repeating limit
            const [percentage, usage] = await getUsagePercentage(orgId, modelId, limitKeys[lIndex], database)
            limit['percentage'] = percentage
            limit['usage'] = usage
            modelLimits[limitKeys[lIndex]] = limit
          }
        }
        if (Object.keys(modelLimits || {}).length) {
          limits[modelId] = modelLimits
        }
      }
      const newModelLimits = structuredClone(getState().userReducer?.modelLimits) || {}
      newModelLimits[orgId] = limits
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          modelLimits: newModelLimits,
        },
      })
    } else {
      // limit call is successful, but there are no limits
      const newModelLimits = structuredClone(getState().userReducer?.modelLimits) || {}
      newModelLimits[orgId] = {}
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          modelLimits: newModelLimits,
        },
      })
    }
  }). catch((error) => {
    console.log(error)
    const newModelLimits = structuredClone(getState().userReducer?.modelLimits) || {}
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        modelLimits: newModelLimits,
        userMessage: `Error getting organization limits`,
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  })
}
