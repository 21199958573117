const ModelInfo = {
  formsV2: {
    description:
      'The second-generation neural network for extracting typed and handwritten text, key-value pairs, tables and checkmarks from documents without training.',
    display: 'General Forms (Forms V2)',
  },
  invoices: {
    description:
      'Our second-generation general extraction model for Invoices/Bills. Extract out generic invoice fields, line items, key-value pairs and rawtext without having to pre-train a model.',
    display: 'Invoices',
  },
  ocr: {
    description:
      'Our state of the art optical character recognition model that performs well on both typed and handwritten text.',
    display: 'OCR',
  },
  custom: {
    description: 'A custom model made for your organization\'s needs. To get a curl snippet for your custom models, visit your "models" page',
    display: 'Custom Model',
  },
}

export default ModelInfo
