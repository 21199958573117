import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'


export const deleteSubOrgUser = (orgId, authKey, userId, subOrgId, subOrgUserId) => async (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgsUsers
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
    'suborgid': subOrgId,
  }
  const resp = await Helpers.fetchDeleteJson(url, headers, {'users': [subOrgUserId]})
      .then((res) => {
        return res
      })
      .catch((res) => {
        console.log('error res', res)
        return {status: 'FAILURE'}
      })
  const wasSuccessful = resp?.failures?.length === 0
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      userMessage: resp?.message || 'Delete Failed',
      notificationIcon: wasSuccessful ? 'check': 'warning',
      notificationType: 2,
    },
  })
  return [wasSuccessful, resp?.message || 'Delete Failed']
}
